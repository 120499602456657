






import Vue from 'vue'
import Contact from '@/components/particles/Contact/Contact.vue'

export default Vue.extend({
  name: 'ContactPage',
  components: { Contact }
})
